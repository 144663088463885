@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@519;703;894&family=Mulish:wght@200;300&display=swap');
@import "~bootstrap/scss/functions";
@import "variables";
@import "~bootstrap/scss/bootstrap";


* {
  outline: none !important;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-clear-button {
  display: none;
}

.MuiInputLabel-root {
  white-space: break-spaces !important;
  width: 100vw !important;
  text-align: left !important;
}

.bolder {
  font-weight: 501 !important;
}

.rounded--1 {
  border-radius: 15px;
}

.rounded--2 {
  border-radius: 1em !important;
}

.bg-light-1 {
  background-color: #f0f4f7;
}

.home-heading {
  font-size: 4.1rem;
  font-weight: 900;
  line-height: 1.1;
}

.factsHome {

  font-size: 3.3rem;
  font-weight: 900;
}

.backImgHome {

  background-image: url("../images/homeimg/Banner.png");

  background-size: cover;

  background-repeat: no-repeat;

}

.howbackgcolor {

  background: linear-gradient(var(--bs-secondary) 5%, var(--bs-primary));
  ;
  background-size: cover;

}

.howitWork {

  background-image: url("../images/homeimg/HowWorks.png");

  background-size: cover;

  background-repeat: no-repeat;

}

.back-Img-App {

  background-image: url("../images/Cedent/bg-01.png");

  background-size: cover;

  background-repeat: no-repeat;

}

.footer-social-icon {
  width: 10%;
  height: 10%;
  cursor: pointer;
}

.footer-contact {
  width: 7%;
  height: 7%;
  cursor: pointer;
}


.AppHeader-social-icon {
  border-radius: 50%;
  height: auto;
  width: 6%;
  cursor: pointer;
}

.subs-terms ul {
  list-style: none;
}

.subs-terms li:before {
  content: "\2022";
  color: #FEC53D;
  font-weight: bold;
  display: inline-block;
  margin-right: 3px;
  margin-left: -17px;
  font-size: 30px;
  line-height: 0px;
}

.f7 {
  font-size: small;
}

.bull {
  content: "\2022";
  font-weight: 900;
  font-size: 1.6rem;
}

input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}


.dashicon {
  width: 2.3rem;
  border: 1px solid lightgray;
  padding: 6px;
  border-radius: 5px;
  //  background-color: lightgray;
  filter: brightness(0) saturate(100%) invert(8%) sepia(41%) saturate(3312%) hue-rotate(215deg) brightness(96%) contrast(90%);
  // filter: brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(706%) hue-rotate(162deg) brightness(93%) contrast(91%);
}

.rrdd {
  color: #FEC53D !important;
}

.loginButton {
  padding: 0.8rem 3.9rem !important;
}

.SubButton {
  padding: 0.8rem 3.2rem !important;
}

.sidebar a:active {
  background-color: aliceblue;
  padding: 1rem 3rem;
}

.home,
.services,
.reports,
.contact,
.events,
.support {
  display: flex;
  margin-left: 260px;
  font-size: 2rem;
}

.popupBox {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.bgbox {
  position: relative;
  width: 75%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 6px;
  overflow: none;
}

.popupBox1 {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.bgbox1 {
  position: relative;
  width: 55%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 6px;
  overflow: none;
}

.bgcloseIcon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(29%);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.buttonstyle .css-10pg8c5-MuiButtonBase-root-MuiButton-root {
  background-color: blue;
  color: white;
  width: 11em;
  border-radius: 1em;
  margin-top: -11px;
  margin-bottom: 8px;
}

.boxSlide1 .css-1m0oeir-MuiSlider-root {
  height: 14px !important;
  color: #de6126 !important;
  width: 100% !important;
}

.boxSlide2 .css-1m0oeir-MuiSlider-root {
  height: 14px !important;
  color: #aade26 !important;
  width: 100% !important;
}

.boxSlide3 .css-1m0oeir-MuiSlider-root {
  height: 14px !important;
  color: #2670de !important;
  width: 100% !important;
}

.boxSlide4 .css-1m0oeir-MuiSlider-root {
  height: 14px !important;
  color: #de26aa !important;
  width: 100% !important;
}

.boxSlide5 .css-1m0oeir-MuiSlider-root {
  height: 14px !important;
  color: #de2626 !important;
  width: 100% !important;
}

.boxSlide6 .css-1m0oeir-MuiSlider-root {
  height: 14px !important;
  color: #26debf !important;
  width: 100% !important;
}

.tbltext .css-1tzx92n-MuiInputBase-root-MuiOutlinedInput-root {
  height: 2em;
}

.tablerow {
  overflow-y: scroll;
}

.reqTable table {
  width: 100%;
}

.w-100 {
  width: 100% !important;
}

.rowModified {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding: 0px !important;
  margin: 0px !important;
}

.reqTable table td {
  padding: 1em 0.5em;
  text-align: left;
}

.editIcon .css-yftdqn-MuiButtonBase-root-MuiButton-root {
  min-width: 0px !important;
}

.proposalTab td {
  width: auto;
}

.proposalTab th {
  width: auto;
}

.customlink {
  text-decoration: none;

  &.active {
    color: $primary !important;
    background-color: $body-bg !important;
    background-image: none !important;

    .imgHeaderActive {
      filter: none;
      background-color: $primary !important;
      color: white !important;
      border: none;
    }
  }
}

.side-menu-item {
  color: #888888;
  font-weight: 600;

  &.active {
    color: $primary !important;
    background-color: $body-bg !important;
    background-image: none !important;

    .imgActive {
      filter: brightness(0) saturate(100%) invert(8%) sepia(41%) saturate(3312%) hue-rotate(215deg) brightness(96%) contrast(90%);
      // filter: brightness(0) saturate(100%) invert(28%) sepia(100%) saturate(706%) hue-rotate(162deg) brightness(93%) contrast(91%);
    }
  }

  &.accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23888888'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
}

.side-menu-sub-item {
  color: #888888;

  &.active {
    color: $primary !important;
    background-color: $body-bg !important;
    background-image: none !important;
  }
}

.sideMenuList {
  overflow-y: auto;
}

.sideMenuLayout {
  width: var(--side-nav-bar-width);
  z-index: 10;
  bottom: 0;
  position: fixed;
  top: 0;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .0s ease-in-out, right .0s ease-in-out;
}

.sideMenuCollapsed {
  margin-left: var(--side-nav-bar-width-ne);
}

.contentPageFull {
  margin-left: 0 !important;
}

.contentPage {
  margin-left: var(--side-nav-bar-width);
  padding-top: var(--nav-header-height);
  // overflow    : hidden;
  // min-height  : 100vh;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .50s ease-in-out, right .50s ease-in-out;
}

.logoHeight {
  height: var(--nav-header-logo-height) !important;
}
.logoBelowText{
  left: 27%;
  bottom: 2px;
  font-size: 11px;
}

.tabledesign tr {
  padding: 0px !important;
}

.mx-5 .css-udygpf-MuiTableCell-root {
  padding: 9px !important;
}

.mx-5 .css-ab003o-MuiTableCell-root {
  padding: 9px !important;
}

.mx-5 .css-qw58bt-MuiTableCell-root {
  padding: 9px !important;
}

.mx-5 .css-qsl6lo-MuiTableCell-root {
  padding: 9px !important;
}

.mx-5 .css-6lznzh-MuiTableCell-root {
  padding: 9px !important;
}

.mt-3 {
  margin-top: 0.5rem !important;
}

.popupbox .bgbox {
  width: 26% !important;
  width: 35% !important;
}

.sideMenuTitleSection {
  height: var(--nav-header-height);
  border-bottom: 1px solid #d2d2d2;
  background-color: var(--bs-white) !important;
  border-top-left-radius: 25px;
  // border-top-right-radius: 25px;
  border-right: 1.4px solid #d1d1d1;
}

.sideMenuList {
  height: calc(90vh - var(--nav-header-height));
  overflow-y: auto;
}

// .sideMenuList::-webkit-scrollbar { /* WebKit */
//   width: 0;
//   height: 0;
// }
.app-vh-content {
  min-height: calc(100vh - 100px);
}

.bg-dark-blue {
  background-color: #192349 !important;
}

.bg-main-light {
  background-color: #f8f8f8 !important;
}

.bg-light-dash {
  background-color: #f8f8f8 !important;
}

.bg-light-1 {
  background-color: #f0f4f7;
}

.bg-light-2 {
  background-color: #f2f2f2;
}

.fw300 {
  font-weight: 300 !important;
}

.svgDashIcon {
  height: 50px !important;
}

.rounded--3 {
  border-radius: 2rem !important;
}

.navHeader {
  height: var(--nav-header-height);
  position: fixed;
  left: var(--side-nav-bar-width);
  top: 0;
  right: 0;
  z-index: 1001;
  border-bottom: 1.4px solid #d1d1d1;
  transition: margin-left .35s ease-in-out, left .35s ease-in-out, margin-right .35s ease-in-out, right .35s ease-in-out;
}

.navHeaderFull {
  left: 0;
}

.dialogRequest .css-1t1j96h-MuiPaper-root-MuiDialog-paper {

  width: 49em !important;
}

.textform {
  height: 8em;
  overflow: auto;
  width: 25em;
}

.dialogct .MuiPaper-elevation {
  max-width: auto !important;
}

.addbutton {
  border-radius: 10px !important;
  border: 1px solid gainsboro !important;
}

.ArchieveButton {
  border-radius: 2em !important;
  color: #de6126 !important;
  border: 1px solid #de6126 !important;
  height: 2.4em !important;
  width: 7em !important;
}

.closensubmitbut {
  width: 117px !important;
  background-color: $primary !important;
  height: 37px !important;
  color: white !important;
}

.closebut {
  width: 117px !important;
  border: 1px solid $primary !important;
  height: 37px !important;
  color: $primary !important;
}

.createbut {
  border-radius: 7px !important;
}

.proposalPagination .css-id3lap-MuiTablePagination-selectLabel {
  margin-bottom: 1px !important;
}

.proposalPagination .css-1iww5t0-MuiTablePagination-displayedRows {
  margin-bottom: 1px !important;
}

.proposalPagination .css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
  margin-bottom: 1px;
}

.newProposal {
  color: #3498DB !important;
}

.Accepted {
  color: #2ECC71 !important;
}

.cedentRes {
  color: #F1948A !important;
}

.insuranceRes {
  color: #17202A !important;
}

.rejected {
  color: #C0392B !important;
}

.riPending {
  color: #ff5733 !important;
}

.pointer {
  cursor: pointer !important;
}

.dash-Box {
  border-radius: 15px;
  border: 1px solid;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  height: 6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.px-6 {
  padding: 0em 2.5em !important;
}

.selectLogin .MuiPaper-elevation {
  padding: 2em;
}

.boxPadding {
  padding: 6px 16px;
}

.loginLbl {
  padding: 0em 0em 1em 0em !important;
  text-align: center;
  font-weight: bold;
}

.vertAlign {
  flex-direction: column;
  justify-content: center;
}

.justifyCenter {
  justify-content: center;
}

.css-xmrfg8-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 14px !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
  border: 1px solid white;
}

.searchBox {
  border-radius: 10px;
  width: 100%;
  padding: 2px 13px;
  margin: 0px;
  font-size: 16px;
  height: 2.5em;
}

.error {
  color: $danger;
}

.proposalUpload {
  justify-content: center;
  text-align: center;
}

.alignCenter {
  text-align: center;
}

.h-100 {
  height: 100%;
}

.vh {
  height: 73vh !important;
}

.justify-content-right {
  justify-content: right;
}

.align-item-center {
  align-items: center;
}

.downloadTemplate {
  animation: blinker 2s linear infinite;
  font-weight: bold;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.handIcon {
  color: #192349;
  transform: rotate(90deg);
}


@media (max-width : 768px) {
  .exeProposalMargin {
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }

  .proposalContArea {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .exeProposalPadding {
    padding: 2rem !important;
  }

  .textEndModified {
    display: flex;
  }
}